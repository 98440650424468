import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class AmountTransferServices{
    getAmountTransferDatas(param){
        const url = `api/amountTransfer`
        const params = {
            'page-index': param.size,
            'from':param.from,
            'to':param.to,
            'month':param.month,
            'today':param.today,
            'year':param.year,
            'searched':param.searched,
            'offset':param.offset,
            'status':param.status,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getFilterAmountTransfer(param){
        const url = `api/amountTransfer/filter`
        console.log(param);
        const params = {
            'page-index': param.size,
            'from':param.from,
            'to':param.to,
            'month':param.month,
            'today':param.today,
            'year':param.year,
            'searched':param.searched,
            'offset':param.offset,
            'status':param.status,
        }
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getAmountTransferElements(){
        const url = `api/amountTransfer/elements`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
    createAmountTransfer(formdata){
        const url = `api/amountTransfer/store`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,formdata,headers);
    }
    editAmountTransfer(formdata,id){
        const url = `api/amountTransfer/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.patch(url,formdata,headers);
    }
    verifyAmountTransfer(id,remarks){
        const url = `api/amountTransfer/verify/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,remarks,headers);
    }
    getLatestTransferId(){
        const url = `api/amountTransfer/transfer/id`
            const headers={
                'Accept':'application/json',
                'x-Request-with': 'XMLHttpRequest',
                'Authorization': 'Bearer ' + store.getters['token'],
            }
            return Api.get(url,headers);
    }
    cancelAmountTransfer(id,remarks){
        const url = `api/amountTransfer/cancel/${id}`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,remarks,headers);
    }
}
export default new AmountTransferServices();