<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'editTransfer'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Edit Transfer
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose', 'newTransfer')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form @submit.prevent="submit" class="bg-theam-secondary">
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">From Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.sender_branch_id"
                              name="branch"
                              id="branch"
                              class="form-control"
                              :disabled="sender_branch_disabled"
                              @change.prevent="senderBranchChange()"
                             required>
                            <option value="">Select Branch</option>
                              <option
                                v-for="(branch, index) in elementDatas.branchs_sender_filter"
                                :key="index"
                                :value="branch.id"
                              required>
                                {{ branch.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="payment_method"
                              class="form-control"
                              v-model="formData.sender_payment_method_id"
                              @change="senderPaymentMethod" required
                            >
                            <option value="" disabled>Select Payment</option>
                              <option
                                v-for="(method, index) in sender_methods"
                                :key="index"
                                :value="method.id"

                              >
                                {{ method.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.sender_bank_id"
                              type="input"
                              name="bank"
                              id="sender_bank"
                              class="form-control"
                              :disabled="sender_bank_disabled"
                            required>
                            <option value="" disabled>Select Bank</option>
                            <option
                                v-for="(method, index) in sender_banks"
                                :key="index"
                                :value="method.id"
                                
                              >
                              {{method.bank}}
                            </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <!-- Transaction id is replaced with Ref. -->
                          <div class="col-md-5 mr-0 pr-0">Ref.</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.transaction_id"
                              type="input"
                              name="transaction_id"
                              id="transaction_id"
                              placeholder="Payment Ref."
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group" id="description">
                      <label class="group-label">Description</label>
                      <div class="group-attribute-container">
                        <div class="row">
                          <div class="col-md-12">
                            <!-- <input
                              v-model="formData.description"
                              type="input"
                              name="description"
                              placeholder="Desc..."
                              class="form-control"
                            /> -->
                            <textarea class="form-control" v-model="formData.description" style="height: 3.1rem;" placeholder="Description..."></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Payment Type</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">To Branch *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.receiver_branch_id"
                              name="branch"
                              id="branch1"
                              class="form-control"
                              :disabled="receiver_branch_disabled"
                              @change.prevent="receiverBranchChange()" required
                            >
                             <option value="">Select Branch</option>
                              <option
                                v-for="(branch, index) in elementDatas.branchs_receiver_filter"
                                :key="index"
                                :value="branch.id"
                              >
                                {{ branch.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Payment Method *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              name="payment_method"
                              class="form-control"
                              v-model="formData.receiver_payment_method_id"
                              @change="receiverPaymentMethod()" required
                            >
                            <option value="" disabled>Select Payment</option>
                              <option
                                v-for="(method, index) in receiver_methods"
                                :key="index"
                                :value="method.id"
                              >
                                {{ method.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Bank Name *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              v-model="formData.receiver_bank_id"
                              type="input"
                              name="bank"
                              id="receiver_bank"
                              class="form-control"
                              :disabled="receiver_bank_disabled" required
                            >
                            <option value="" disabled>Select Bank</option>
                            <option
                                v-for="(method, index) in receiver_banks"
                                :key="index"
                                :value="method.id"
                              >
                              {{method.bank}}
                            </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Date *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.date"
                              type="date"
                              name="date"
                              id="date"
                              placeholder="1547220XD"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Transfer ID</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.transfer_id"
                              id="income_id"
                              class="form-control"
                              placeholder="TXN005"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">
                            Transfer Amount *
                          </div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              v-model="formData.transfer_amount"
                              type="number"
                              min="1"
                              step="any"
                              name="transfer_amount"
                              class="form-control"
                              placeholder="NRP 0.00"
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{(disableSubmitButton)?'Submitting':'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <TransferSlip />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import TransferSlip from "./Slip";
export default {
  components: { TransferSlip},
  data() {
    return {
      formData: {
        id: "",
        date:"",
        receiver_branch_id: "",
        sender_branch_id: "",
        sender_payment_method_id: "",
        receiver_payment_method_id: "",
        transaction_id: "",
        sender_bank_id: "",
        receiver_bank_id: "",
        description: "",
        transfer_amount: "",
      },
      sender_methods:'',
      receiver_methods:'',
      sender_banks:'',
      receiver_banks:'',
      sender_branch_disabled:false,
      receiver_branch_disabled:false,
      sender_bank_disabled:true,
      receiver_bank_disabled:true,
      disableSubmitButton:false,
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "dataLists4",
      "dataLists5",
      "dataLists6",
      "dataLists7",
      "dataLists8",
      "dataLists9",
      "dataId",
      "modalId",
      "modalMode",
    ]),
    ...mapGetters("amountTransfers",['amountTransferDatas','elementDatas','slipData']),
  },
  methods: {
    submit() {
      if (this.modalMode == "edit") {
        this.disableSubmitButton= true;
        Services.editAmountTransfer(this.formData,this.formData.id).then(res=>{
          //this.setNotification(res.data.success_message);
          this.$store.commit("setDataId", res.data.id);
          this.$store.commit("amountTransfers/setSlipData",res.data.data);
          this.$store.dispatch("modalWithMode", { id: 'afterTransferSlip',mode:"read"});
          this.$emit('parent-event');
          this.disableSubmitButton = false;
        }).catch(err=>{
            console.log(err);
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
            }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
            }
            this.disableSubmitButton = false;
        })
      }
    },
     setNotification(msg){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",msg);
    },
    senderBranchChange(){
        this.sender_bank_disabled = true;
        let details=[];
        let methods = this.formData.sender_branch_id;
            details = this.elementDatas.payment_methods_sender.filter(function (data) {
                return data.branch == methods;
            });
        this.sender_methods = {...details};
        this.formData.sender_payment_method_id = this.sender_methods[0].id;
    },
    senderPaymentMethod(){
            let payment_bank = this.formData.sender_payment_method_id;
            let values = this.elementDatas.payment_methods_sender.find(function (data) {
                if(data.id == payment_bank){
                    return data.title;
                } 
            });
            if(values.title.toLowerCase() == "bank"){
                    if(this.elementDatas.banks_sender.length != 0){
                        this.sender_bank_disabled = false;
                        let details=[];
                        let methods = this.formData.sender_branch_id;
                            details = this.elementDatas.banks_sender.filter(function (data) {
                                return data.branch == methods;
                            });
                        this.sender_banks= {...details};
                        if(details.length > 0){
                            this.formData.sender_bank_id = details[0].id;
                        }else{
                            this.formData.sender_bank_id ="";
                        }
                    }else{
                        this.sender_bank_disabled = false;
                        this.formData.sender_bank_id = '';
                    }
                }
                else{
                    this.sender_bank_disabled = true;
                    this.formData.sender_bank_id = '';
                }    
    },
    receiverBranchChange(){
        this.receiver_bank_disabled = true;
        let details=[];
        let methods = this.formData.receiver_branch_id;
            details = this.elementDatas.payment_methods_receiver.filter(function (data) {
                return data.branch == methods;
            });    
        this.receiver_methods = {...details};
        this.formData.receiver_payment_method_id = this.receiver_methods[0].id;
    },
    receiverPaymentMethod(){
       let payment_bank = this.formData.receiver_payment_method_id;
            let values = this.elementDatas.payment_methods_receiver.find(function (data) {
                if(data.id == payment_bank){
                    return data.title;
                } 
            });
            if(values.title.toLowerCase() == "bank"){
                    if(this.elementDatas.banks_receiver.length != 0){
                        this.receiver_bank_disabled = false;
                        let details=[];
                        let methods = this.formData.receiver_branch_id;
                            details = this.elementDatas.banks_receiver.filter(function (data) {
                                return data.branch == methods;
                            });
                        this.receiver_banks= {...details};
                        if(details.length > 0){
                            this.formData.receiver_bank_id = details[0].id;
                        }else{
                            this.formData.receiver_bank_id ="";
                        }
                    }else{
                        this.receiver_bank_disabled = false;
                        this.formData.receiver_bank_id = '';
                    }
                }
                else{
                    this.receiver_bank_disabled = true;
                    this.formData.receiver_bank_id = '';
                }    
    },
    getElements() {
      Services.getAmountTransferElements()
        .then((res) => {
          this.$store.commit("amountTransfers/setElementDatas", res.data.data);
          let id = this.dataId;
          let details = this.amountTransferDatas.find(function (data) {
            return data.id == id;
          });
          if(this.elementDatas.branchs_receiver_filter.length == 1){
              this.receiver_branch_disabled = true;
          }
          if(this.elementDatas.branchs_sender_filter.length == 1){
              this.sender_branch_disabled = true;
          }
          this.formData={...details};
          this.receiverBranchChange();
          this.formData.receiver_payment_method_id=details.receiver_payment_method_id;
          this.senderBranchChange();
          this.formData.sender_payment_method_id=details.sender_payment_method_id;
          this.senderPaymentMethod();
          this.receiverPaymentMethod();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    clearData() {
      this.formData.id = "";
      this.formData.receiver_branch_id = "";
      this.formData.sender_branch_id = "";
      this.formData.sender_payment_method_id = "";
      this.formData.receiver_payment_method_id= "";
      this.formData.transaction_id = "";
      this.formData.sender_bank_id = "";
      this.formData.receiver_bank_id = "";
      this.formData.description = "";
      this.formData.transfer_amount = "";
      this.formData.sender_methods='',
      this.formData.receiver_methods='';
      this.formData.sender_banks='';
      this.formData.receiver_banks='';
      this.formData.sender_bank_disabled=true;
      this.formData.receiver_bank_disabled=true;
      this.formData.sender_branch_disabled=false;
      this.formData.receiver_branch_disabled=false;
      this.formData.disableSubmitButton=false;
    },
  },
  watch:{
    modalId(value) {
      if (value == "editTransfer") {
        this.clearData();
        this.getElements();
      }
    },
  },
};
</script>

