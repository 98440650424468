<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'transferSlip'||modalId=='afterTransferSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div v-if="details.status === 1" class="slip-ribbon ribbon-success">
            <div class="slip-ribbon-text">VERIFIED</div>
          </div>
          <div class="ribbon-wrapper" v-if="(details.status == 2)">
                <div class="ribbon bg-danger">Canceled</div>
            </div>

          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Transfer Slip - #{{ details.transfer_id }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" title="Print" @click="printSlip()"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  title="Close"
                  @click="$store.dispatch('modalClose', 'salesSlip')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
              <p class="mb-0 font-print-xxs">
                {{details.sender_branch.name}}
                <br />
                Tel No:{{authData.org_detail.phone}} / {{authData.org_detail.email}}
                <br />
                PAN:{{authData.org_detail.pan_vat}}
              </p>

              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-4 col-lg-4 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Transfer</label>
                <p class="mg-b-0">
                  From Branch : {{ details.sender_branch.name }}
                </p>
                <p class="mg-b-0">
                  To Branch: {{ details.receiver_branch.name }}
                </p>
              </div>

                <div class="col-sm-4 col-lg-4 order-1 order-sm-0">
                <p class="text-center font-l m-0">Transfer Slip</p>
                <hr class="print-sm-hr print-hr-margin" />
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">
                  Transfer Id : #{{ details.id}}
                </p>
                <p class="mg-b-0 text-right">DATE :{{ details.date }}</p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->

            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-12 col-lg-12 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <div class="table-responsive">
                  <table
                    class="table table-bordered mg-b-0 table_lr_border color-secondary"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">
                          From Payment Type
                        </th>
                        <th scope="col" class="border-right_1">
                          To Payment Type
                        </th>
                        <th scope="col" class="border-right_1">Ref.</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ details.sender_payment_method.title }} <span v-if="details.sender_bank_id">-{{details.sender_bank.bank}}</span></td>
                        <td>{{ details.receiver_payment_method.title }} <span v-if="details.receiver_bank_id">-{{details.receiver_bank.bank}}</span></td>
                        <td>{{ details.transaction_id }}</td>
                        <td> {{ parseDigitForSlip(details.transfer_amount) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
            </div>
            <div class="row justify-content-between mt-1 font-s font-w-400">
              <div
                class="col-sm-0 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0 pr-1"
              >
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Amount in Word</label>
                  <p class="mb-0">{{ toWord(details.transfer_amount) }} only</p>
                </div>
              </div>
              <!-- col -->
              <div
                class="col-sm-0 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0 pl-1"
              >
                <!-- Note -->
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Description</label>
                  <p class="mb-2">
                    {{ details.description }}
                  </p>
                </div>
                <!-- Note -->
              </div>
              <!-- col -->
            </div>
            <hr />
            <div>
              <div class="row mt-2">
                
                <div class="col-lg-6" v-if="details.status == 1">
                  <p class="text-left mb-1">Verified By: {{details.verify_user.name}}</p>
                  <p class="text-left mb-1">Verified On: {{details.verify_date}}</p>
                  <p class="text-left">Verified For: {{details.verify_remarks}}</p>
                </div>
                <div class="col-lg-6" v-if="details.status == 2">
                  <p class="text-left mb-1">Canceled By:{{details.cancel_user.name}}</p>
                  <p class="text-left mb-1">Canceled On: {{details.cancel_date}}</p>
                  <p class="text-left">Canceled For:{{details.cancel_remarks}}</p>
                </div>
                <div class="col-lg-6">
                  <p class="text-right">
                    Created By: {{ details.user.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "modalId",
      "authData",
      "modalState",
      "dataId",
    ]),
    ...mapGetters("amountTransfers",["amountTransferDatas","slipData"])
  },
  methods:{
    printSlip(){
      window.print();
    }
  },
  watch: {
    modalId(value) {
      let id = this.dataId;
      if(value == 'transferSlip'){
        this.details = this.amountTransferDatas.find(function (data) {
          return data.id == id;
        });
      }
      if(value == 'afterTransferSlip'){
        this.details = this.slipData;
      }
    },
  },
};
</script>