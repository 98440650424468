<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'verifySlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content border0">
          <div v-if="details.status === 1" class="slip-ribbon ribbon-success">
            <div class="slip-ribbon-text">VERIFIED</div>
          </div>
          <div class="ribbon-wrapper" v-if="(details.status == 2)">
                <div class="ribbon bg-danger">Canceled</div>
            </div>

          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Transfer Slip - #{{ threeDigit(details.transfer_id) }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <!-- <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" title="Print" @click="window.print()"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download" title="Download"></i>
                </div> -->
                <div
                  class="bill-head-icon mr-1"
                  title="Close"
                  @click="$store.dispatch('modalClose', 'salesSlip')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
              <p class="mb-0 font-print-xxs">
                {{details.sender_branch.name}}
                <br />
                Tel No:{{authData.org_detail.phone}} / {{authData.org_detail.email}}
                <br />
                PAN:{{authData.org_detail.pan_vat}}
              </p>

              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">From</label>
                <p class="mg-b-0">
                  From Branch : {{ details.sender_branch.name }}
                </p>
                <p class="mg-b-0">
                  To Branch: {{ details.receiver_branch.name }}
                </p>
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">
                  Transfer ID : #{{details.transfer_id }}
                </p>
                <p class="mg-b-0 text-right">DATE :{{ details.date }}</p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->

            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-12 col-lg-12 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <div class="table-responsive">
                  <table
                    class="table table-bordered mg-b-0 table_lr_border color-secondary"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">
                          From Payment Type
                        </th>
                        <th scope="col" class="border-right_1">
                          To Payment Type
                        </th>
                        <th scope="col" class="border-right_1">Ref.</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ details.sender_payment_method.title }}</td>
                        <td>{{ details.receiver_payment_method.title }}</td>
                        <td>{{ details.transaction_id }}</td>
                        <td> {{ parseDigitForSlip(details.transfer_amount) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
            </div>
            <div class="row justify-content-between mt-1 font-s font-w-400">
              <div
                class="col-sm-0 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0 pr-1"
              >
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Amount in Word</label>
                  <p class="mb-0">{{ toWord(details.transfer_amount) }} only</p>
                </div>
              </div>
              <!-- col -->
              <div
                class="col-sm-0 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0 pl-1"
              >
                <!-- Note -->
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Desciption</label>
                  <p class="mb-2">
                    {{ details.description }}
                  </p>
                </div>
                <!-- Note -->
              </div>
              <!-- col -->
            </div>
            <hr />
            <div>
              <div class="row mt-2">
                <div class="col-lg-6">
                  <p class="text-left">
                    Created By: {{ details.user.name }}
                  </p>
                  <p class="text-left">
                    Created On: {{ details.date }}
                  </p>
                </div>
              </div>
            </div>
             <div>
              <div class="row mt-2">
                <div class="col-lg-9">
                  <p class="text-left">
                    <label for="comment">Verify Comment</label>
                    <textarea class="form-control" v-model="verify_comment"></textarea>
                  </p>
                </div>
                <div class="col-lg-3">
                  <div class="text-right">
                    <button class="btn btn-success" @click.prevent="verifyTransfer(details.id)" :disabled="disableSubmitButton">{{(disableSubmitButton)?'Verifying...':'Verify'}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      details: [],
      disableSubmitButton:false,
      verify_comment:'',
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "authData",
      "modalId",
      "authData",
      "modalState",
      "dataId",
      "today",
    ]),
    ...mapGetters("amountTransfers",["amountTransferDatas","slipData"])
  },
  methods:{
    verifyTransfer(id){
        this.disableSubmitButton=true;
        Services.verifyAmountTransfer(id,{'remarks':this.verify_comment}).then((response) => {
          if(response.status == 200){
              this.amountTransferDatas.forEach((item,index) => {
              if(item.id == id){
                    this.amountTransferDatas[index]['status'] = 1;
                    this.amountTransferDatas[index]['verify_remarks']=this.verify_comment;
                    this.amountTransferDatas[index]['verify_user'] = this.authData.user;
                    this.amountTransferDatas[index]['verify_date'] = this.today;
                }              
              });
              this.disableSubmitButton=false;
              let success = response.data.success_message;
              this.setNotification(success);
            }
          }).catch(error => { 
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
              this.disableSubmitButton=false;
          })
    },
    setNotification(msg){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",msg);
    },
  },
  watch: {
    modalId(value) {
      let id = this.dataId;
      if(value == 'verifySlip'){
        this.verify_comment = "";
        this.details = this.amountTransferDatas.find(function (data) {
          return data.id == id;
        });
      }
    },
  },
};
</script>