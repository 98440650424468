<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >Fund Transfer</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Fund Transfer</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 tx-right">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newTransfer',
              mode: 'create',
            })
          "
        >New Transfer</button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select
            v-model="params.size"
            @change="getFilteredDataWithoutDates"
            id="amount_transfer_filter_size"
            class="mr-2 mg-t-5"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <DayMonthFilter @clicked="monthDayClicked" />
          <div class="mr-2 d-inline-block">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From </label>
            <input
              type="date"
              v-model="params.from"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              id="amount_transfer_filter_date_from"
              @change="getFilteredDataWithoutDates"
            />
            <label class="filter-lavel mb-0 ml-1 mr-1 mg-t-5">To </label>
            <input
              type="date"
              v-model="params.to"
              placeholder="To"
              class="filter-input mg-t-5"
              id="amount_transfer_filter_date_to"
              @change="getFilteredDataWithoutDates"
            />
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0 mg-t-5">
          <input
            type="text"
            v-model="params.searched"
            class="float-right"
            id="amount_transfer_search"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Transfer ID</th>
              <th class="wd-20p">From</th>
              <th class="wd-20p">To</th>
              <th class="wd-10p">Amount</th>
              <th class="wd-10p">Status</th>
              <th class="wd-15p text-center table-end-item" v-if="checkIfAnyPermission(['view_amount_transfer','cancel_amount_transfer','edit_amount_transfer'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && amountTransferDatas.length>0">
            <tr v-for="(transfer, index) in amountTransferDatas" :key="index">
              <th scope="row" class="table-start-item">{{ pageSerialNo+index }}.</th>
              <td>{{ transfer.date }}</td>
              <td>#{{transfer.id}}</td>
              <td>
                {{ transfer.sender_branch.name }}
                <span
                  v-if="transfer.sender_bank_id != null"
                >- {{transfer.sender_bank.bank}}</span>
                <span v-else>-{{transfer.sender_payment_method.title}}</span>
              </td>
              <td>
                {{ transfer.receiver_branch.name }}
                <span
                  v-if="transfer.receiver_bank_id != null"
                >- {{transfer.receiver_bank.bank}}</span>
                <span v-else>-{{transfer.receiver_payment_method.title}}</span>
              </td>
              <td>{{parseDigitForSlip(transfer.transfer_amount )}}</td>
              <td>
                <span class="text-primary" v-if="transfer.status==0">Pending</span>
                <span class="text-success" v-if="transfer.status==1">Verified</span>
                <span class="text-danger" v-if="transfer.status==2">Cancel</span>
              </td>
              <td class="text-right table-end-item" v-if="checkIfAnyPermission(['view_amount_transfer','cancel_amount_transfer','edit_amount_transfer','verify_amount_transfer'])">
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="View"
                  :id="'amountTransferView'+transfer.id"
                  v-if="checkSinglePermission('view_amount_transfer')"
                  @click="view('transferSlip', transfer.id)"
                >
                  <i class="fas fa-eye tx-success"></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="Edit"
                  :id="'amountTransferEdit'+transfer.id"
                  @click="edit('editTransfer',transfer.id)"
                  v-if="transfer.status == 0 && checkSinglePermission('edit_amount_transfer') && allowed_branches.includes(transfer.sender_branch.id)"
                >
                  <i class="fa fa-edit"></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="Verify"
                  :id="'amountTransferVerify'+transfer.id"
                  @click="verify('verifySlip',transfer.id)"
                  v-if="transfer.status == 0 && checkSinglePermission('verify_amount_transfer') && allowed_branches.includes(transfer.receiver_branch.id)"
                >
                  <i class="far fa-check-square"></i>
                </a>
                <a
                  href="javascript:;"
                  class="mr-3"
                  title="Cancel"
                  :id="'amountTransferCancel'+transfer.id"
                  @click="cancel(transfer.id)"
                  v-if="transfer.status == 0 && checkSinglePermission('cancel_amount_transfer') && allowed_branches.includes(transfer.sender_branch.id)"
                >
                  <i class="fas fa-times-circle tx-danger"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && amountTransferDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align:center;">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align:center;">Loading.....</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align:center">{{error_message}}</td>
            </tr>
          </tbody>
        </table>
        <Paginate
        v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
    <TransferSlip />
    <Verify @parent-event="getData()" />
    <Create @parent-event="getData()" />
    <Edit @parent-event="getData()" />
  </div>
</template>
<script>
import TransferSlip from "./Slip";
import Create from "./create";
import Edit from "./edit";
import Verify from "./verify";
import { Search } from "../../../../../mixins/search";
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import Services from "./Services/Services";
import DayMonthFilter from "../../../../shared/SearchDayMonthComponent.vue";
import _ from "lodash";
export default {
  mixins: [Search],
  components: { TransferSlip, Create, Verify, Paginate, Edit, DayMonthFilter },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "authData",
      "today",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "dataLists4",
      "dataLists5",
      "dataLists6",
      "dataLists7",
      "dataLists8",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("amountTransfers", ["amountTransferDatas", "elementDatas"]),
  },
  data() {
    return {
      params: {
        size: "10",
        searched: "",
        from: "",
        to: "",
        offset: 0,
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      loading: true,
      error: false,
      page: 1,
      pageSerialNo: 1,
      totalPageCount: 0,
      error_message: "",
      allowed_branches:{},
    };
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
        this.params.from = "";
        this.params.to = "";
      }
      //make api call
      this.getFilteredData();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/amountTransfer/${id}`);
    },
    getFilteredData() {
      this.loading = true;
      let params = {
        size: this.params.size,
        today: this.params.day,
        month: this.params.month + 1,
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        offset: this.offset,
      };
      this.searchFilter(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        size: this.params.size,
        today: "",
        month: "",
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        offset: this.offset,
      };
      this.searchFilter(params);
    },
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      this.getFilteredDataWithoutDates();
    }, 1000),
    searchFilter(param) {
      this.page = 1;
      this.pageSerialNo = 1;
      this.params.offset = 0;
      Services.getFilterAmountTransfer(param)
        .then((res) => {
          this.$store.commit(
            "amountTransfers/setAmountTransferDatas",
            res.data.data.data
          );
          this.allowed_branches = res.data.data.branch_permissions;
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.error = true;
          this.loading = false;
          let error = err.response.data.error_message;
          this.setNotification(error);
        });
    },
    verify(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    cancel(id) {
      this.$swal({
        title: "Do you really want to cancel this Amount Transfer?",
        html: `<label>Remarks: </label>
        <textarea id="cancel-remarks" class="swal2-input"></textarea>
       `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let remarks = document.getElementById("cancel-remarks").value;
          // cancel the payin with remarks
          return Services.cancelAmountTransfer(id, { remarks: remarks })
            .then((response) => {
              this.amountTransferDatas.forEach((item, index) => {
                if (item.id == id) {
                  this.amountTransferDatas[index]["status"] = 2;
                  this.amountTransferDatas[index]["cancel_remarks"] = remarks;
                  this.amountTransferDatas[index]["cancel_user"] = this.authData.user;
                  this.amountTransferDatas[index]["cancel_date"] = this.today;
                }
              });
              if (response.status == 200) {
                let success = response.data.success_message;
                this.setNotification(success);
              }
            })
            .catch((error) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if (error.response.status == 500) {
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
            });
        },
      });
    },
    setNotification(msg) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", msg);
    },
    getData() {
      this.loading = true;
      Services.getAmountTransferDatas(this.params)
        .then((res) => {
          this.$store.commit(
            "amountTransfers/setAmountTransferDatas",
            res.data.data.data
          );
          this.allowed_branches = res.data.data.branch_permissions;
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.data.error_message == "Access Denied.") {
            this.error_message = "Access Denied !!!";
          } else {
            this.error_message = "Error Fetching data from the server.";
          }
          this.loading = false;
          this.error = true;
        });
      //this.getElements();
    },
  
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>